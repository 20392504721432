const translation = {
  offRules: 'Ametlikud Reeglid',
  // Navigation pages
  play: 'MÄNGI',
  recent: 'Hiljuti',
  howToPlay: 'KUIDAS MÄNGIDA',

  // Card
  signUp: 'MÄNGIMISEKS REGISTREERI',
  viewMy: 'VAATA ENDA KAARTI',
  view: 'VAATA KAARTI',
  registered: 'REGISTREERITUD',
  entry: 'Osaleja',
  entries: 'Osalejad',
  contestClosed: 'BINGO VÕISTLUS ON SULETUD',
  ifNecessary: 'KUI ON VAJALIK',

  //Signup modal
  reg: 'Oled registreeritud mängima OlyBeti Bingot',
  rel: 'Sinu Bingo kaardi avaldamiseni on jäänud:',
  come: 'Enda kaardi nägemiseks tule tagasi mängu alguseks',
  priz: 'Auhinnad',
  regen: 'BINGO KAARTI UUENDATAKSE MÄNGU JOOKSUL AUTOMAATSELT.',
  //Wait modal
  hangTight: 'PÜSI MEIEGA!',
  cardToBeGenerated: 'Oodake BINGO KAARDI LOOMIST.',
  mayTakeUpFewMinutes: 'SEE VÕTAB AEGA KUNI MÕNI MINUT',

  // How to play page
  vert: 'VERTIKAAL',
  horiz: 'HORISONTAAL',
  diag: 'DIAGONAAL',
  fullCard: 'KOGU KAART',
  earnEntriesIntoDraw:
    'AUHINDU TEENID TERVE REA, DIAGONAALI VÕI KOGU KAARDI TÄIS SAAMISE EEST. RUUT MÄRGITAKSE TABATUKS RUUDUS MÄRGITUD SÜNDMUSE MÄNGUS ASET LEIDMISEL.',
  autoScoresDuringGame: 'BINGO KAARTI UUENDATAKSE MÄNGU JOOKSUL AUTOMAATSELT.',
  clickForMoreInfo: 'Lisainformatsiooni jaoks kliki',
  SOG: 'SOG - Vise väravale (iga vise loeb mis läheb väravaraamide vahele. Ei sisalda blokeeritud viskeid või posti tabatud viskeid)',
  PPG: 'PPG - Power play värav (värav, mis skooritakse enamuses mängiva tiimi poolt. Näiteks, kui tiim mängib 5v4, 5v3 või 4v3 enamuses)',
  SHG: 'SHG - Vähemuses skooritud värav (värav, mis skooritakse karistust kandva tiimi poolt)',
  PIM: 'PIM - Karistused kokku minutites',
  FOPercent: 'FO% - Lahtivisete võitmise protsent',
  PTS: 'PTS - Punktid (värav või resultatiivne sööt)',
  entryPerRow: 'OSALEJA REA KOHTA',
  totalEntries: 'OSALEJAID KOKKU',
  perRow: 'REA KOHTA',

  //Home page
  bingoMayTakeUp:
    'Bingo kaardite tulemuste kinnitamiseks võib kuluda kuni 20 minutit alates mängu lõpust',
  welcomeTo: 'Tere tulemast',
  reviewInRecent: 'Vaata enda viimaseid kaarte "Hiljuti" vaate alt',
  thanksForPlaying:
    'Täname mängimast OlyBeti Bingot! Vaata enda 2022-2023 hooaja kaarte "Hiljuti" vaate alt',
  agreeToRules: 'Registreerudes nõustud ja oled teadlik',

  //Recent page
  noRecentContests: 'Hiljutisi võistlusi ei ole',
  //Game field page
  gamePrizeText:
    'Võida €100 Freebet, kui sinu kaardil täituvad kõik ruudud, või €5 Freebet, kui saad vähemalt ühe rea või diagonaali. Auhindu jagatakse tööpäevadel.',
  cardUpdateAfterPeriod: 'Kaart uueneb iga 30 minuti järel',
  anyLine: 'MISTAHES LIIN',
  vertLines: 'VERTIKAALSED LIINID',
  line: 'LIIN',
  horzLines: 'HORISONTAALSED LIINID',

  //Onboarding page
  letsGetStarted: 'Alustame',
  makeSureToEnterSelections:
    'Päevaauhindade eest võistlemiseks sisesta enda valikud iga päev. Pea meeles sisestada valik ka viigimurdja küsimusele. Saad muuta enda valikuid enne mängu algust. Punkte teenid iga õige ennustuse eest.',
  playWithFriends:
    'Mängi koos sõpradega. Kes on parim? Pane sõbrad proovile mängides privaatses liigas. Mine edetabeli sektsiooni, vajuta "Loo" nupule ja jaga PIN koodi sõpradele liitumiseks.',
  scoring: 'Punktiarvestus',
  challengeFriends: 'Pane sõbrad proovile',
  skip: 'jäta vahele',
  next: 'järgmine',

  logout: 'Logi välja',
  returnToGamezone: 'Mine tagasi Mängutsooni',
  rules: 'REEGLID',
  back: 'Tagasi',

  bingo: 'BingOly',
  gamezone: 'Mängutsoon',
  justAFewMinutes: 'Ainult mõned minutid, palun värskenda lehte',
  fourCorners: 'NELI NURKA',
  rulesNotFound: 'Reegleid ei leitud',
  offWebsiteText:
    'PÕHILISE TULEMUSTE OTSUSTAMISE ALLIKANA KASUTATAKSE SÜNDMUSE AMETLIKKU VEEBILEHTE. AMETLIKU VEEBILEHE PUUDUMISEL VÕTAME ARVESSE TEISI USALDUSVÄÄRSEID ALLIKAID.',
};
export default translation;
