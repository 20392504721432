import { Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import logo from '@/assets/images/Clutchbet/bingo-logo.png';

import { RoutesEnum } from '@/enums/routes.enum';

const SidebarTop = () => {
  return (
    <div>
      <Link style={{ width: '177px', height: '54px' }} to={RoutesEnum.play}>
        <Image alt='logo' h='130px' src={logo} />
      </Link>
    </div>
  );
};

export default SidebarTop;
