import React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Bling as GPT } from 'react-gpt';

import { Wrapper } from './AdsBanner.styles';

const AdsBanner: React.FC = () => {
  return (
    <Wrapper>
      {/**
       * <GPT
        adUnitPath='/21668666170/Game_Hub/bingo_en'
        sizeMapping={[
          { viewport: [0, 0], slot: [320, 50] },
          { viewport: [320, 0], slot: [320, 50] },
          { viewport: [728, 0], slot: [320, 50] },
          { viewport: [970, 0], slot: [320, 50] },
        ]}
      />
       */}
    </Wrapper>
  );
};

export default AdsBanner;

export const EXTERNAL_LINKS = {
  OfficialRules:
    'https://assets.ctfassets.net/hyke4xphwvaw/3qalqOW6a9Lm1cvdZ97ROI/0019c2276add31d9c344f2bd5c34765f/ClutchBet_Big_Game_Bingo_Bowl_-_Official_Rules_02.01.24.pdf',
  TermsConditions:
    'https://assets.ctfassets.net/hyke4xphwvaw/eqctLg4OSWAVR0BqZLixx/bcc3ab6bd6c0e23816a57ab9686657d4/ClutchBet_Free_to_Play_T_Cs_02.01.24.pdf',
  Ad: '/', // link was broken
};
