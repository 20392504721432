import React from 'react';

import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import ChevronLeft from '@/assets/icon/ChevronLeft';
import Burger from '@/assets/icon/Olybet/menu.svg';
import logoHeader from '@/assets/images/Clutchbet/logo-mobile-header.png';

import { useSettings } from '@/hooks/useSettings.hook';

import { RoutesEnum } from '@/enums/routes.enum';

import { tKeys } from '@/i18n/resourses';

import colors from '@/theme/colors';

import * as S from './Header.styles';

type IHeaderProps = {
  handleClick: () => void;
};

const Header: React.FC<IHeaderProps> = ({ handleClick }) => {
  const { pagesWithBackButton } = useSettings();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <Flex alignItems='center' bgColor={colors.darkBlue} h='80px' id='header'>
      <Flex
        alignItems='center'
        justifyContent='center'
        position='relative'
        width='100%'
      >
        {!pagesWithBackButton ? (
          <S.BurgerButton onClick={handleClick}>
            <img alt='' height={34} src={Burger} width={34} />
          </S.BurgerButton>
        ) : (
          <S.BurgerButton onClick={() => navigate(RoutesEnum.play)}>
            <ChevronLeft />
          </S.BurgerButton>
        )}
        {pathname === RoutesEnum.help ? (
          <div
            style={{
              color: '#FFFFFF',
              fontSize: '20px',
              fontWeight: '600',
              textAlign: 'center',
              textTransform: 'capitalize',
            }}
          >
            {t(tKeys.howToPlay)}
          </div>
        ) : (
          <Link to={RoutesEnum.play}>
            <S.ImageLogo alt='logo' src={logoHeader} />
          </Link>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
