import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLocalStorage } from './useLocalStorage.hook';

export const useUserState = () => {
  const { value: state } = useLocalStorage<string>(StorageKeysEnum.state);

  const isColorado = state === 'CO';
  const isIowa = state === 'IA';

  return { isColorado, isIowa };
};
