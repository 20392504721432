import React from 'react';

import './Card.css';

import { Route, Routes } from 'react-router-dom';

import { AppRoutes } from '@/routes';

import Layout from '@/components/Layout';

import AppLayout from './AppLayout';

const App: React.FC = () => {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route element={<Layout />}>
          {AppRoutes.map((route) => (
            <Route element={route.element} key={route.path} path={route.path} />
          ))}
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
