import React from 'react';

import { Box, Flex, Grid } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { useNavLinks } from '@/hooks/useNavLinks';
import { useSettings } from '@/hooks/useSettings.hook';

import { RoutesEnum } from '@/enums/routes.enum';

import * as S from './PageNavigation.styles';

const MobileNav = () => {
  const { navLinks } = useNavLinks();

  return (
    <>
      <Grid
        gap='5px'
        gridTemplateColumns='repeat(3, 1fr)'
        height='50px'
        px='20px'
      >
        {navLinks.map(({ selected, ...link }) => {
          return (
            <Flex
              alignItems='center'
              as={NavLink}
              borderBottom='3px solid'
              borderColor={selected ? '#0688C3' : 'transparent'}
              color={selected ? '#0688C3' : '#333'}
              fontWeight='bold'
              justifyContent='center'
              key={link.linkData.to as RoutesEnum}
              textDecoration='none'
              textTransform='uppercase'
              to={link.linkData.to}
            >
              {link.label}
            </Flex>
          );
        })}
      </Grid>
    </>
  );
};

const PageNavigation: React.FC = () => {
  const { isDesktop, isLoginToContinuePage, pagesWithBackButton } =
    useSettings();
  const { navLinks } = useNavLinks();

  const navigation = isDesktop ? (
    <Box bgColor='white' borderRadius='20px 20px 0 0'>
      <S.NavWrapper>
        {navLinks.map((link) => (
          <S.StyledLink
            key={link.linkData.to as RoutesEnum}
            selected={link.selected}
            to={link.linkData.to}
          >
            {link.label}
          </S.StyledLink>
        ))}
      </S.NavWrapper>
    </Box>
  ) : (
    <MobileNav />
  );

  if (isLoginToContinuePage) return null;

  return !pagesWithBackButton ? navigation : null;
};

export default PageNavigation;
