import { useMemo } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import useMediaQuery from '@/hooks/useMediaQuery.hook';

import { MediaQueriesEnum } from '@/enums/mediaQueries.enum';
import { RoutesEnum } from '@/enums/routes.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { addIdToRoute } from '@/routes/types';

import { useLocalStorage } from './useLocalStorage.hook';

export const useSettings = () => {
  const { value: lang } = useLocalStorage<string>(StorageKeysEnum.i18nextLng);

  const { id } = useParams();
  const { pathname } = useLocation();
  const [isMobile, isTablet, isDesktop] = useMediaQuery([
    MediaQueriesEnum.isMobile,
    MediaQueriesEnum.isTablet,
    MediaQueriesEnum.isDesktop,
  ]);

  const pagesWithBackButton = useMemo(
    () =>
      [
        RoutesEnum.game,
        RoutesEnum.rules,
        addIdToRoute(RoutesEnum.contestRules, id),
      ].includes(pathname as RoutesEnum),
    [id, pathname],
  );

  const isPlayPage = pathname === RoutesEnum.play;
  const isLoginToContinuePage = pathname === RoutesEnum.loginToContinue;

  return {
    isMobile,
    isTablet,
    isDesktop,
    isPlayPage,
    isLoginToContinuePage,
    pagesWithBackButton,
    lang,
  };
};
