import React from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import '@/i18n';
import App from '@/App';
import { setupStore } from '@/store';
import theme from '@/theme';

import IframeResizer from '@/components/IframeResizer';

import { AppStyles, Reset } from '@/App/App.styles';
import FontStyles from '@/App/FontStyles';

import 'bootstrap/dist/css/bootstrap.min.css';

const store = setupStore();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <IframeResizer />
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <FontStyles />
        <Reset />
        <AppStyles>
          <App />
        </AppStyles>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
);
