import { FC, PropsWithChildren, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { linkToGZ } from '@/utils';

import { useJWT } from '@/hooks/useJWT.hook';
import { useLocalStorage } from '@/hooks/useLocalStorage.hook';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsKeysEnum } from '@/enums/searchParamsKeys.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { FEATURE_NON_LOGIN_ENABLED } from '@/utils/constants';

import { useGenerateJWTByTokenMutation } from '@/services/api.service';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { jwt, setJWT, removeJWT } = useJWT();
  const { value: langValue, setItem: setLang } = useLocalStorage<string>(
    StorageKeysEnum.i18nextLng,
  );
  const { value: isOnboarded } = useLocalStorage<boolean>(
    StorageKeysEnum.onboarding,
  );

  const [triggerJWT, { isLoading }] = useGenerateJWTByTokenMutation();

  const lang = searchParams.get(SearchParamsKeysEnum.lang);
  const token = searchParams.get(SearchParamsKeysEnum.token);

  useEffect(() => {
    if (!token && !jwt) {
      navigate(RoutesEnum.loginToContinue);
    }
  }, [jwt, token]);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang, () => setLang(lang));
      searchParams.delete(SearchParamsKeysEnum.lang);

      navigate({ search: searchParams.toString() }, { replace: true });
    }
    // eslint-disable-next-line
  }, [i18n, lang, searchParams, setLang, setSearchParams]);

  useEffect(() => {
    const handleLogin = async () => {
      try {
        const res = await triggerJWT({ token: token as string }).unwrap();
        setJWT(res.token);
        searchParams.delete(SearchParamsKeysEnum.token);

        navigate({ search: searchParams.toString() }, { replace: true });
        // TODO: uncomment when onboarding page will need
        // if (!isOnboarded) {
        //   navigate(RoutesEnum.onboarding);
        // }
      } catch (e) {}
    };

    if (token) {
      removeJWT();
      handleLogin();
    }
    // eslint-disable-next-line
  }, [searchParams, setSearchParams, token]);

  // TODO: uncomment when onboarding page will need
  // useEffect(() => {
  //   if (!isOnboarded && !token) {
  //     navigate(RoutesEnum.onboarding);
  //   }
  // }, [isOnboarded, navigate, token]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <>
      {children}
      {/*isLoading && <LoaderScreen /> */}
    </>
  );
};
