import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { ApiTagsEnum } from '@/enums/apiTags.enum';

import {
  GetOneGameRes,
  GetPageResponse,
  GetRecentGamesRes,
  GetTodayGamesRes,
} from '@/types/domain';

import { baseQueryWithRedirect } from '@/utils/api';

export const api = createApi({
  reducerPath: 'mainApi',
  tagTypes: Object.values(ApiTagsEnum),
  baseQuery: baseQueryWithRedirect,
  endpoints: (builder) => ({
    getTodayGames: builder.query<GetTodayGamesRes, { jwt: string }>({
      query: () => '/games-today',
      providesTags: [ApiTagsEnum.GET_TODAY_GAMES],
    }),
    getRecentGames: builder.query<GetRecentGamesRes, void>({
      query: () => '/games-recent',
      providesTags: [ApiTagsEnum.GET_RECENT_GAMES],
    }),
    getOneGame: builder.query<GetOneGameRes, { id: string }>({
      query: ({ id }) => `/games/${id}`,
      providesTags: [ApiTagsEnum.GET_ONE_GAME],
    }),

    // POST {{domain}}/games/13/join-with-code?code=AMR89M
    signUp: builder.mutation<
      { success: boolean },
      { code?: string; id: number }
    >({
      query: ({ code, id }) => ({
        url: !!code
          ? `/games/${id}/join-with-code?code=${code}`
          : `/games/${id}/join`,
        method: 'POST',
      }),
    }),

    generateJWTByToken: builder.mutation<{ token: string }, { token: string }>({
      query: (body) => ({ url: '/login', method: 'POST', body }),
    }),
    getRules: builder.query<GetPageResponse, { lang: string }>({
      query: ({ lang }) => `/page/rules?lang=${lang}`,
    }),
    getContestRules: builder.query<
      GetPageResponse,
      { id: string; lang: string }
    >({
      query: ({ id, lang }) => `/page/contest:${id}?lang=${lang}`,
    }),
    getAbbrRules: builder.query<GetPageResponse, { lang: string }>({
      query: ({ lang }) => `/page/abbr-rules?lang=${lang}`,
    }),
    share: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({ url: `/games/${id}/reward-card`, method: 'POST' }),
    }),
  }),
});

export const {
  useGetTodayGamesQuery,
  useGetRecentGamesQuery,
  useGetOneGameQuery,
  useLazyGetOneGameQuery,
  useSignUpMutation,
  useGenerateJWTByTokenMutation,

  useGetRulesQuery,
  useGetContestRulesQuery,
  useGetAbbrRulesQuery,
  useShareMutation,
} = api;
