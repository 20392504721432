import React from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useSettings } from '@/hooks/useSettings.hook';

import Header from '../../Header';

export const Wrapper = styled('div')(() => {
  const { isMobile } = useSettings();

  return {
    alignItems: 'start',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    margin: !isMobile ? '26px auto' : '',
  };
});

export const LogoWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gridColumn: 2,
});

const BackToPrevPage = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: '0',
  background: 'transparent',
  outline: '0',
  cursor: 'pointer',
  textDecoration: 'none',
  justifySelf: 'start',
  top: '20px',
  left: '0',
});

const BackText = styled('p')(({ theme }) => {
  const { isMobile } = useSettings();

  return {
    fontSize: isMobile ? '12px' : '14px',
    fontWeight: '700',
    color: theme.colors.white,
  };
});

const PageHeader: React.FC<{ onBurgerClick: () => void }> = ({
  onBurgerClick,
}) => {
  const navigate = useNavigate();
  const { isDesktop, pagesWithBackButton } = useSettings();

  if (isDesktop) return null;

  // Mobile header
  return <Header {...{ handleClick: onBurgerClick }} />;
};

export default PageHeader;
