const translation = {
  offRules: 'Официальные Правила',
  // Navigation pages
  play: 'ИГРАТЬ',
  recent: 'Недавние',
  howToPlay: 'КАК ИГРАТЬ',

  // Card
  signUp: 'ВОЙТИ ДЛЯ ИГРЫ',
  viewMy: 'СМОТРЕТЬ МОЮ КАРТУ',
  view: 'СМОТРЕТЬ КАРТУ',
  registered: 'ЗАРЕГИСТРИРОВАН',
  entry: 'Участник',
  entries: 'Участники',
  contestClosed: 'СОРЕВНОВАНИЕ BINGO ЗАКРЫТО',
  ifNecessary: 'ЕСЛИ НЕОБХОДИМО',

  //Signup modal
  reg: 'Вы зарегистрированы для игры в OlyBet Bingo',
  rel: 'Ваша карта Bingo будет раскрыта через:',
  come: 'Для просмотра своей карты возвращайтесь к началу игры',
  priz: 'Призы',
  regen: 'КАРТА BINGO АВТОМАТИЧЕСКИ ОБНОВЛЯЕТСЯ ВО ВРЕМЯ ИГРЫ',
  //Wait modal
  hangTight: 'ПРОДОЛЖАЙТЕ!',
  cardToBeGenerated: 'Ожидание создания КАРТЫ BINGO.',
  mayTakeUpFewMinutes: 'ЭТО МОЖЕТ ЗАНЯТЬ ДО НЕСКОЛЬКИХ МИНУТ',

  // How to play page
  vert: 'ВЕРТИКАЛЬ',
  horiz: 'ГОРИЗОНТАЛЬ',
  diag: 'ДИАГОНАЛЬ',
  fullCard: 'ВСЯ КАРТА',
  earnEntriesIntoDraw:
    'ЗАРАБАТЫВАЙТЕ ПРИЗЫ ПРИ ЗАПОЛНЕНИИ ЦЕЛОГО РЯДА, ДИАГОНАЛИ ИЛИ ВСЕЙ КАРТЫ. СЕКТОР БУДЕТ УЧТЁН, ЕСЛИ ОТМЕЧЕННОЕ В НЁМ СОБЫТИЕ СОСТОЯЛОСЬ.',
  autoScoresDuringGame: 'КАРТА BINGO АВТОМАТИЧЕСКИ ОБНОВЛЯЕТСЯ ВО ВРЕМЯ ИГРЫ',
  clickForMoreInfo: 'Для получения подробной информации нажмите на',
  SOG: 'SOG – Бросок в створ ворот (любой бросок, засчитанный как бросок в створ ворот. Не включает блокированные или попавшие в штангу броски)',
  PPG: 'PPG – Гол в большинстве (гол, забитый командой, играющей с преимуществом в составе. Например, команда, которая играет с преимуществом в 5v4, 5v3 или 4v3)',
  SHG: 'SHG – Гол в меньшинстве (гол, забитый командой, игрок которой отбывает пенальти)',
  PIM: 'PIM – Штрафы в минутах',
  FOPercent: 'FO% — Процент вбрасываний',
  PTS: 'PTS – Очки (гол или результативная передача)',
  entryPerRow: 'УЧАСТНИК НА РЯД',
  totalEntries: 'ОБЩЕЕ КОЛ-ВО УЧАСТНИКОВ',
  perRow: 'НА РЯД',

  //Home page
  bingoMayTakeUp:
    'Подтверждение результатов карты Bingo может занять до 20 минут после завершения игры',
  welcomeTo: 'Добро пожаловать в',
  reviewInRecent: 'Смотрите свои последние карты во вкладке «Недавние»',
  thanksForPlaying:
    'Благодарим за участие в OlyBet Bingo! Смотрите свои карты за сезон 2022-2023 во вкладке «Недавние»',
  agreeToRules:
    'Регистрируясь, Вы подтверждаете, что ознакомились и соглашаетесь с',

  //Recent page
  noRecentContests: 'Нет недавних игр',
  //Game field page
  gamePrizeText:
    'Выиграйте 100 € Фрибет, если заполните все секторы на своей карте, или 5 € Фрибет, если Вам удастся собрать хотя бы один ряд или диагональ. Призы будут зачислены в рабочие часы.',
  cardUpdateAfterPeriod: 'Карта обновляется каждые 30 минут',
  anyLine: 'ЛЮБАЯ ЛИНИЯ',
  vertLines: 'ВЕРТИКАЛЬНЫЕ ЛИНИИ',
  line: 'ЛИНИЯ',
  horzLines: 'ГОРИЗОНТАЛЬНЫЕ ЛИНИИ',

  //Onboarding page
  letsGetStarted: 'Давайте начнём!',
  makeSureToEnterSelections:
    'Не забывайте вносить свои ответы каждый день для получения ежедневных призов. Не забудьте указать вопрос тай-брейкера до подтверждения своего выбора. Вы можете изменить свой выбор до начала игры. Зарабатывайте очки за каждый правильно сделанный прогноз.',
  playWithFriends:
    'Играйте с друзьями. Кто знает больше? Сразитесь в игре против своих друзей в приватной лиге. Перейдите в раздел таблицы лидеров, нажмите на кнопку Создать для начала и поделитесь PIN-кодом, чтобы Ваши друзья присоединились.',
  scoring: 'Подсчёт очков',
  challengeFriends: 'Бросьте вызов друзьям',
  skip: 'пропустить',
  next: 'далее',

  logout: 'Выйти',
  returnToGamezone: 'Вернуться в Игровую зону',
  rules: 'ПРАВИЛА',
  back: 'Назад',

  bingo: 'BingOly',
  gamezone: 'Игровая зона',
  justAFewMinutes: 'Всего несколько минут, пожалуйста, обновите страницу',
  fourCorners: 'ЧЕТЫРЕ УГЛА',
  rulesNotFound: 'Правила не найдены',
  offWebsiteText:
    'ОФИЦИАЛЬНЫЙ ВЕБ-САЙТ СОБЫТИЯ БУДЕТ СЧИТАТЬСЯ ОСНОВНЫМ ИСТОЧНИКОМ РЕЗУЛЬТАТОВ. ЕСЛИ ОН НЕДОСТУПЕН, ТО БУДУТ ИСПОЛЬЗОВАТЬСЯ ДРУГИЕ НАДЁЖНЫЕ ИСТОЧНИКИ ИЛИ ПРОСМОТР ВИДЕО.',
};
export default translation;
