import { Box, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { useNavLinks } from '@/hooks/useNavLinks';
import { useSettings } from '@/hooks/useSettings.hook';

export const FooterNavigation = () => {
  const { isDesktop, isLoginToContinuePage } = useSettings();
  const { footerLinks } = useNavLinks();

  if (!isDesktop || isLoginToContinuePage) return null;

  return (
    <Box border='1px solid #9e9e9e4d' mb='30px' py='30px'>
      <Flex justify='space-around' m='0 auto' maxW='690px'>
        {footerLinks.map((link, index) => (
          <Box
            _hover={{
              textDecor: 'underline',
            }}
            as={Link}
            color='#111111'
            fontWeight='bold'
            key={index}
            textDecoration='none'
            textTransform='uppercase'
            to={link.href}
          >
            {link.label}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};
