import React, { useEffect, useLayoutEffect, useRef } from 'react';

const IframeResizer = () => {
  const iframeTypeRef = useRef<string>('');
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  useLayoutEffect(() => {
    // const handleMessage = (e: MessageEvent) => {
    //   console.log('START MESSAGING');
    //   if (window.self !== window.top) {
    //     console.log('----- iframe -----');
    //     if (typeof e.data === 'object') {
    //       iframeTypeRef.current = e.data.type;
    //       e.data.height = document.body.clientHeight;
    //     }
    //
    //     console.log('message listener', {
    //       action: 'resize',
    //       height: document.body.clientHeight,
    //       type: iframeTypeRef.current,
    //     });
    //     window.parent.postMessage(e.data, '*');
    //   } else {
    //     console.log('----- no iframe -----');
    //   }
    // };
    //
    // window.addEventListener('message', handleMessage);

    resizeObserverRef.current = new ResizeObserver((entries) => {
      console.log('resizeObserver', iframeTypeRef.current);

      console.log('message', {
        action: 'resize',
        height: entries[0].target.clientHeight,
      });
      window.parent.postMessage(
        {
          action: 'resize',
          height: entries[0].target.clientHeight,
        },
        '*',
      );
    });

    resizeObserverRef.current?.observe(document.body);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current?.disconnect();
      }
    };
  }, []);

  return null;
};

export default IframeResizer;
