import { Flex, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import banner from '@/assets/images/Clutchbet/banner_300x1050.jpeg';

import { useSettings } from '@/hooks/useSettings.hook';
import { useUserState } from '@/hooks/useUserState';

import { EXTERNAL_LINKS } from '../AdsBanner/AdsBanner';

export const Banner = () => {
  const { isDesktop, isLoginToContinuePage } = useSettings();
  const { isColorado, isIowa } = useUserState();

  if (!isDesktop || isLoginToContinuePage) return null;

  return (
    <Flex
      alignItems='flex-start'
      as={Link}
      justifyContent='center'
      padding='20px'
      to={EXTERNAL_LINKS.Ad}
    >
      <Image src={banner} w='100%' />
    </Flex>
  );
};
