import styled from 'styled-components';

export const Menu = styled('img')({
  paddingLeft: '15px',
  position: 'absolute',
  left: '10px',
});
export const BurgerButton = styled('button')({
  position: 'absolute',
  background: 'none',
  border: 'none',
  left: '10px',
  cursor: 'pointer',
  padding: '0px',
  margin: '0px',
});
export const InfoButton = styled('button')({
  position: 'absolute',
  background: 'none',
  border: 'none',
  right: '0',
  cursor: 'pointer',
  padding: '0px',
  margin: '0px',
});
export const ImageLogo = styled('img')({
  width: '140px',
  height: '40px',
});
