import React, { useEffect } from 'react';

import { Box, Flex, Grid, Image } from '@chakra-ui/react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';

import bannerTop from '@/assets/images/Clutchbet/banner-top-mobile.jpeg';
import logo from '@/assets/images/Clutchbet/bingo-logo.png';
import headerBg from '@/assets/images/Clutchbet/header-bg.jpg';

import { useSettings } from '@/hooks/useSettings.hook';

import { EXTERNAL_LINKS } from '@/components/AdsBanner/AdsBanner';
import { DesktopSideNavbar } from '@/components/DesktopSidebarNav';
import PageNavigation from '@/components/PageNavigation';

import { RoutesEnum } from '@/enums/routes.enum';

import { addIdToRoute } from '@/routes/types';

import { Back } from './Back';
import { BackToGameZone } from './BackToGameZone';
import { Banner } from './Banner';
import { FooterNavigation } from './Footer/FooterNavigation';
import PageHeader from './PageHeader';
import Sidebar from './Sidebar';
import SidebarBottom from './Sidebar/SidebarBottom';
import SidebarContent from './SidebarContent';
import SidebarTop from './SidebarTop';

const CONTAINER_W = '690px';

const Layout: React.FC = () => {
  const { id } = useParams();
  const { isDesktop, isPlayPage, isLoginToContinuePage } = useSettings();
  const [isSidebarVisible, setIsSidebarVisible] = React.useState(false);
  const { pathname } = useLocation();
  const rootElement = document.getElementById('root');

  const handleOpenSidebar = React.useCallback(
    () => setIsSidebarVisible(true),
    [setIsSidebarVisible],
  );

  const handleCloseSidebar = React.useCallback(
    () => setIsSidebarVisible(false),
    [setIsSidebarVisible],
  );

  useEffect(() => {
    if (isSidebarVisible && rootElement) {
      document.body.style.overflow = 'hidden';
      rootElement.style.overflow = 'hidden';
    } else if (!isSidebarVisible && rootElement) {
      document.body.style.overflow = 'auto';
      rootElement.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
      (rootElement as any).style.overflow = 'auto';
    };
  }, [isSidebarVisible, rootElement]);

  useEffect(() => {
    handleCloseSidebar();
  }, [handleCloseSidebar, pathname]);

  useEffect(
    () => window.parent.postMessage({ type: 'scrollTop' }, '*'),
    [pathname],
  );

  const isOnboardingPage = pathname === RoutesEnum.onboarding;
  const isRulesPage = pathname === RoutesEnum.rules;
  const isContestRulesPage =
    pathname === addIdToRoute(RoutesEnum.contestRules, id);

  // Mobile Layout
  if (!isDesktop) {
    return (
      <>
        <PageHeader {...{ onBurgerClick: handleOpenSidebar }} />
        {isPlayPage && (
          <Link
            referrerPolicy='no-referrer'
            target='_black'
            to={EXTERNAL_LINKS.Ad}
          >
            <Image src={bannerTop} w='100%' />
          </Link>
        )}
        <PageNavigation />
        <Outlet />

        {isSidebarVisible && (
          <Sidebar
            BottomComponent={<SidebarBottom />}
            ContentComponent={<SidebarContent />}
            TopComponent={<SidebarTop />}
            isVisible={isSidebarVisible}
            onCloseClick={handleCloseSidebar}
          />
        )}
      </>
    );
  }

  return (
    <>
      {/* {!IS_HEADER_HIDDEN && renderHeader()} */}

      <>
        <PageHeader {...{ onBurgerClick: handleOpenSidebar }} />

        {isDesktop && (
          <Box
            backgroundImage={`url(${headerBg})`}
            backgroundPosition='center'
            backgroundSize='cover'
            h={isLoginToContinuePage ? '190px' : 'auto'}
            width='100%'
          >
            <BackToGameZone />
            <Flex
              alignItems='center'
              justifyContent='center'
              marginTop={'0'}
              paddingBottom={isLoginToContinuePage ? '30px' : '100px'}
              paddingTop={isLoginToContinuePage ? '30px' : '50px'}
            >
              <Image
                alt=''
                src={logo}
                width={isLoginToContinuePage ? '120px' : '197px'}
              />
            </Flex>
          </Box>
        )}

        <Grid
          as='main'
          gridTemplateColumns={isDesktop ? `1fr ${CONTAINER_W} 1fr` : '1fr'}
          height='auto'
          minHeight={isLoginToContinuePage ? 'calc(100vh - 190px)' : '100vh'}
          padding={isDesktop ? '0 15px 15px' : '0'}
          position='relative'
          width='100%'
        >
          <Banner />

          <Box>
            <Box
              borderRadius='20px'
              left='0'
              margin='0 auto'
              maxW={CONTAINER_W}
              position={isDesktop ? 'absolute' : 'inherit'}
              right='0'
              top='-80px'
            >
              <Back />
              <PageNavigation />
              <Outlet />
            </Box>
          </Box>

          <Banner />
        </Grid>

        <FooterNavigation />

        <DesktopSideNavbar />
      </>
    </>
  );
};

export default Layout;
