import React from 'react';

import { StyledBackground } from './GlobalBackground.styles';

export interface GlobalBackgroundProps {
  children: React.ReactNode;
}

const GlobalBackground: React.FC<GlobalBackgroundProps> = ({ children }) => {
  return <StyledBackground>{children}</StyledBackground>;
};

export default GlobalBackground;
