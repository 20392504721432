import React from 'react';

const ChevronLeft: React.FC = () => {
  return (
    <svg
      fill='none'
      height='32'
      viewBox='0 0 32 32'
      width='32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='chevron-left'>
        <g id='Icon'>
          <path
            d='M20.0917 6.83714C20.6252 7.37064 20.6252 8.23562 20.0917 8.76912L12.861 15.9999L20.0917 23.2306C20.6252 23.7641 20.6252 24.6291 20.0917 25.1626C19.5582 25.6961 18.6932 25.6961 18.1597 25.1626L9.96299 16.9658C9.42948 16.4323 9.42948 15.5674 9.96299 15.0339L18.1597 6.83714C18.6932 6.30364 19.5582 6.30364 20.0917 6.83714Z'
            fill='white'
          />
        </g>
      </g>
    </svg>
  );
};

export default ChevronLeft;
