import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import Background from '../Background';

import SidebarAnimation from './Sidebar.animation';
import { Bottom, Content, Top } from './Sidebar.styles';
import { SidebarProps } from './types';

const Sidebar: React.FC<SidebarProps> = ({
  ContentComponent,
  onCloseClick,
  TopComponent,
  BottomComponent,
  isVisible,
}) => {
  return (
    <>
      <Background isVisible={isVisible} onCloseClick={onCloseClick} />
      <SidebarAnimation inProp={isVisible}>
        <Flex
          background='#151833'
          flexDirection='column'
          height='100%'
          justify='space-between'
          overflowY='auto'
          width='250px'
        >
          <Box>
            <Top>{TopComponent}</Top>
            <Content>{ContentComponent}</Content>
          </Box>
          <Bottom>{BottomComponent}</Bottom>
        </Flex>
      </SidebarAnimation>
    </>
  );
};

export default Sidebar;
