import React, { Suspense } from 'react';

import { Outlet } from 'react-router-dom';

import { AuthProvider } from '@/providers/AuthProvider';

import GlobalBackground from '@/components/GlobalBackground';
import LoaderScreen from '@/components/LoaderScreen';

import { LocalStorageProvider } from '@/context/localStorage.context';

const AppLayout: React.FC = () => {
  return (
    <LocalStorageProvider>
      <GlobalBackground>
        <AuthProvider>
          <Suspense fallback={<LoaderScreen />}>
            <Outlet />
          </Suspense>
        </AuthProvider>
      </GlobalBackground>
    </LocalStorageProvider>
  );
};

export default AppLayout;
