import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { LinkProps, useLocation } from 'react-router-dom';

import { linkToGZ } from '@/utils';

import { StadiumIcon } from '@/assets/icon/components/Stadium.icon';
import { TrophyIcon } from '@/assets/icon/components/Trophy.icon';

import { useLocalStorage } from '@/hooks/useLocalStorage.hook';
import useMediaQuery from '@/hooks/useMediaQuery.hook';

import { EXTERNAL_LINKS } from '@/components/AdsBanner/AdsBanner';

import { MediaQueriesEnum } from '@/enums/mediaQueries.enum';
import { RoutesEnum } from '@/enums/routes.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { tKeys } from '@/i18n/resourses';

interface IHeaderNavigation {
  label: string;
  icon?: React.ReactNode;
  selected: boolean;
  linkData: LinkProps;
}
export const useNavLinks = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isMobile] = useMediaQuery(MediaQueriesEnum.isMobile);
  const { value: lang } = useLocalStorage<string>(StorageKeysEnum.i18nextLng);
  const desktopNavLinks = useMemo<IHeaderNavigation[]>(
    () => [
      {
        label: 'My cards',
        selected: pathname === RoutesEnum.play,
        linkData: {
          to: RoutesEnum.play,
        },
      },
      {
        label: 'PRIZING',
        selected: pathname === RoutesEnum.prizing,
        linkData: {
          to: RoutesEnum.prizing,
        },
      },
      {
        label: t(tKeys.howToPlay),
        selected: pathname === RoutesEnum.help,
        linkData: {
          to: RoutesEnum.help,
        },
      },
    ],
    [pathname, t],
  );

  const mobileNavLinks = useMemo<IHeaderNavigation[]>(
    () => desktopNavLinks.filter((x) => x.linkData.to !== RoutesEnum.help),
    [desktopNavLinks],
  );

  const navLinks = useMemo<IHeaderNavigation[]>(
    () => desktopNavLinks,
    [desktopNavLinks, isMobile, mobileNavLinks],
  );

  const sidebarNavLinks = useMemo(
    () => [
      {
        label: 'Bingo',
        icon: <TrophyIcon />,
        renderIcon: (props: any) => <TrophyIcon {...props} />,
        selected: true,
        linkData: {
          to: RoutesEnum.play,
        },
      },
      {
        label: t(tKeys.gamezone),
        icon: <StadiumIcon />,
        renderIcon: (props: any) => <StadiumIcon {...props} />,
        selected: false,
        linkData: {
          to: linkToGZ(lang),
        },
      },
    ],
    [lang, t],
  );

  const footerLinks: Array<{ label: string; href: string }> = [
    {
      label: 'Clutchbet Sportsbook',
      href: EXTERNAL_LINKS.Ad,
    },
    {
      label: 'Terms & Conditions',
      href: EXTERNAL_LINKS.TermsConditions,
    },
    {
      label: 'Official Rules',
      href: EXTERNAL_LINKS.OfficialRules,
    },
  ];

  return { footerLinks, navLinks, sidebarNavLinks };
};
