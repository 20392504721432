import React, { SVGProps } from 'react';

export const TrophyIcon: React.FC<SVGProps<any>> = ({ fill = 'white' }) => {
  return (
    <svg
      fill='none'
      height='32'
      viewBox='0 0 32 32'
      width='32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.7462 2H6.67383C6.67383 13.087 13.0777 18.3492 13.0777 18.3492H18.4146C18.4146 18.3492 24.8185 13.087 24.8185 2H15.7462Z'
        stroke={fill}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.4'
      />
      <path
        d='M18.1594 25.2941C20.5757 26.8462 20.2095 30 20.2095 30H11.2854C11.2854 30 10.9192 26.8462 13.3356 25.2941H18.1606H18.1594Z'
        stroke={fill}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.4'
      />
      <path
        d='M24.5571 5.7912H29.2162C29.2162 5.7912 29.2453 6.44881 29.1782 7.4194C28.8982 11.4969 25.5024 14.6595 21.416 14.6595'
        stroke={fill}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.4'
      />
      <path
        d='M6.66536 5.7912H2.00629C2.00629 5.7912 1.97715 6.44881 2.0443 7.4194C2.32433 11.4969 5.72012 14.6595 9.80646 14.6595'
        stroke={fill}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.4'
      />
      <path
        d='M13.0781 18.3492C13.0781 18.3492 14.7165 21.4283 13.3341 25.2941'
        stroke={fill}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.4'
      />
      <path
        d='M18.324 18.3492C18.324 18.3492 16.6857 21.4283 18.0681 25.2941'
        stroke={fill}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.4'
      />
    </svg>
  );
};
