import { createGlobalStyle } from 'styled-components';

import MontserratBoldTTF from '@/fonts/Montserrat-Bold.ttf';
import MontserratRegularTTF from '@/fonts/Montserrat-Regular.ttf';
import MontserratSemiBoldTTF from '@/fonts/Montserrat-SemiBold.ttf';

const FontStyles = createGlobalStyle`

  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratRegularTTF}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratSemiBoldTTF}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratBoldTTF}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }
`;

export default FontStyles;
