import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useSettings } from '@/hooks/useSettings.hook';

import { RoutesEnum } from '@/enums/routes.enum';

export const StyledBackground = styled('div')(() => {
  const { pathname } = useLocation();
  const { isMobile } = useSettings();
  const isGameFieldPage = pathname === RoutesEnum.game;

  const iGameFieldBg = !isMobile && isGameFieldPage;

  return {
    backgroundColor: '#E3E9F1',
    position: 'relative',
  };
});
