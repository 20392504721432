import React from 'react';

import { useLocation } from 'react-router-dom';

import { useNavLinks } from '@/hooks/useNavLinks';

import SidebarButton from './Sidebar/SidebarButton/SidebarButton';

const SidebarContent = () => {
  const { pathname } = useLocation();
  const { sidebarNavLinks } = useNavLinks();

  return (
    <>
      {sidebarNavLinks.map((item, id) => (
        <SidebarButton key={id} {...item} />
      ))}
    </>
  );
};

export default SidebarContent;
