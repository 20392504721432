const translation = {
  offRules: 'Règlements officiels',
  // Navigation pages
  play: 'JOUER',
  recent: 'RÉCENT',
  howToPlay: 'COMMENT JOUER',

  // Card
  signUp: 'Inscrivez-vous pour jouer',
  viewMy: 'Voir ma carte',
  view: 'Voiri la carte',
  registered: 'REGISTERED',
  entry: 'Entry',
  entries: 'Entries',
  contestClosed: 'BINGO CONTEST CLOSED',
  ifNecessary: 'IF NECESSARY',

  //Signup modal
  reg: 'Vous êtes inscrit(e) pour jouer au Bingo de la Olybet',
  rel: 'Votre carte de Bingo sera révélée dans :',
  come: 'Come back to see your card when the event begins',
  priz: 'Prix',
  regen:
    'Les jetons se placent automatiquement sur la carte de Bingo pendant le match ',
  //Wait modal
  hangTight: 'HANG TIGHT!',
  cardToBeGenerated: 'Waiting for BINGO CARD TO BE GENERATED.',
  mayTakeUpFewMinutes: 'THIS CAN TAKE UP TO A FEW MINUTES',
  // How to play page
  vert: 'VERTICAL',
  horiz: 'HORIZONTAL',
  diag: 'DIAGONAL',
  fullCard: 'FULL CARD',
  earnEntriesIntoDraw:
    'EARN PRIZES FOR GETTING A FULL ROW, DIAGONAL OR FULL CARD. YOUR CELLS WILL SCORE IF THE EVENT IN THE CELL BECOMES TRUE.',
  autoScoresDuringGame: 'BINGO CARD AUTO-SCORES DURING GAME',
  clickForMoreInfo: 'For more information, click here for',
  SOG: 'SOG – Shot on Goal (any shot scored as a shot on goal. Does not include blocked shots or shots that hit the post)',
  PPG: 'PPG – Power Play Goal (a goal scored by a team playing with a man advantage. For example, a team that is playing at a man advantage of 5v4, 5v3, or 4v3)',
  SHG: 'SHG – Short-Handed Goal (a goal scored by a team with a player currently serving a penalty)',
  PIM: 'PIM – Penalties in Minutes',
  FOPercent: 'FO% - Face-off Percentage',
  PTS: 'PTS – Points (either a goal or an assist)',
  entryPerRow: 'ENTRY PER ROW',
  totalEntries: 'TOTAL ENTRIES',
  perRow: 'PER ROW',

  //Home page
  bingoMayTakeUp:
    'Bingo cards may take up to 30 minutes post game to score all events',
  welcomeTo: 'Welcome to',
  reviewInRecent: 'Review your past cards in the recent tab',
  thanksForPlaying: '',
  agreeToRules:
    'En signant, vous reconnaissez et vous acceptez de vous conformer aux',
  //Recent page
  noRecentContests: 'No recent contests',
  //Game field page
  gamePrizeText:
    'Each line you score in Bingo will earn you one entry into the random draw for the chance to win [prize goes here]. One winner will be selected at [time] on [date].',
  cardUpdateAfterPeriod: 'Card will update every 30 minutes',
  anyLine: 'ANY LINE',
  vertLines: 'VERT LINES',
  line: 'LINE',
  horzLines: 'HORZ LINES',

  //Onboarding page
  letsGetStarted: 'Let’s Get Started',
  makeSureToEnterSelections:
    'Make sure to enter your selections each day to compete for daily prizes. Remember to enter your selection for the tie breaker question before submitting your pick. You can edit your picks up until the start of the live game. Earn points for each correct prediction made.',
  playWithFriends:
    'Play with Friends. Who’s got game? Challenge your friends to play in a private league. Go to the leaderboard section, hit the Create button to start, and share the PIN code with your friends to join.',
  scoring: 'Scoring',
  challengeFriends: 'Challenge Friends',
  skip: 'skip',
  next: 'next',

  logout: 'Log out',
  returnToGamezone: 'Return to GameZone',
  rules: 'RULES',
  back: 'Back',

  bingo: 'BingOly',
  gamezone: 'Gamezone',
  justAFewMinutes: 'Just a few minutes, please refresh the page',
  fourCorners: 'FOUR CORNERS',
  rulesNotFound: 'Rules not found',
  offWebsiteText:
    'OFFICIAL WEBSITE OF THE EVENT WILL BE CONSIDERED AS PRIMARY SOURCE OF RESULTS. IF NOT AVAILABLE OTHER REPUTABLE SOURCES OR LIVE FOOTAGE WILL BE USED.',
};
export default translation;
