import { Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { ReactComponent as BackIcon } from '@/assets/images/backArrow.svg';

import { useSettings } from '@/hooks/useSettings.hook';

import { RoutesEnum } from '@/enums/routes.enum';

export const Back = () => {
  const { pagesWithBackButton } = useSettings();
  return pagesWithBackButton ? (
    <Flex
      _hover={{
        textDecoration: 'underline',
      }}
      alignItems='center'
      as={Link}
      color='white'
      gap='20px'
      position='absolute'
      textDecoration='none'
      to={RoutesEnum.play}
      top='-40px'
    >
      <BackIcon />
      <span>Lobby</span>
    </Flex>
  ) : null;
};
