import { Box, Grid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { linkToGZ } from '@/utils';

import { useJWT } from '@/hooks/useJWT.hook';
import { useLocalStorage } from '@/hooks/useLocalStorage.hook';
import { useNavLinks } from '@/hooks/useNavLinks';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { tKeys } from '@/i18n/resourses';

export const LogoutButton = styled('button')({
  border: '2px solid #1EB9F1',
  borderRadius: '6px',
  fontSize: '14px',
  fontWeight: '700',
  color: '#FFFFFF',
  fontFamily: "'Montserrat', sans-serif",
  width: '100%',
  height: '48px',
  textTransform: 'uppercase',
  background: 'transparent',
  outline: 'none',
});

const SidebarBottom = () => {
  const { t } = useTranslation();
  const { jwt, removeJWT } = useJWT();
  const { value: lang } = useLocalStorage<string>(StorageKeysEnum.i18nextLng);
  const { footerLinks: links } = useNavLinks();

  const handleLogout = () => {
    removeJWT();
    window.location.assign(linkToGZ(lang));
  };

  return (
    <div style={{ position: 'relative', margin: 'auto 0 0 0' }}>
      <Grid as='ul' gap='16px' marginTop='24px'>
        {links.map((item, id) => (
          <Box as='li' key={id}>
            <Box
              as={Link}
              color='white'
              fontSize='14px'
              fontWeight='bold'
              textDecoration='none'
              textTransform='uppercase'
              to={item.href}
            >
              {item.label}
            </Box>
          </Box>
        ))}

        {jwt && (
          <LogoutButton onClick={handleLogout}>{t(tKeys.logout)}</LogoutButton>
        )}
      </Grid>
    </div>
  );
};

export default SidebarBottom;
