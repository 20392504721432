import { Flex, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { linkToGZ } from '@/utils';

import ChevronLeft from '@/assets/icon/ChevronLeft';
import clutchbetGZLogo from '@/assets/images/Clutchbet/clutchbet-gz-logo.png';

import { useSettings } from '@/hooks/useSettings.hook';

export const BackToGameZone = () => {
  const { isLoginToContinuePage } = useSettings();

  if (isLoginToContinuePage) return null;

  return (
    <Flex
      align='center'
      as={Link}
      left='20px'
      pos='absolute'
      to={linkToGZ()}
      top='20px'
    >
      <ChevronLeft />
      <Image src={clutchbetGZLogo} w='150px' />
    </Flex>
  );
};
