const translation = {
  offRules: 'Oficiālie Noteikumi',
  // Navigation pages
  play: 'SPĒLĒT',
  recent: 'NESENIE',
  howToPlay: 'KĀ SPĒLĒT',

  // Card
  signUp: 'REĢISTRĒJIETIES, LAI SPĒLĒTU',
  viewMy: 'SKATĪT MANU KARTIŅU',
  view: 'SKATĪT KARTIŅU',
  registered: 'REĢISTRĒTS',
  entry: 'Dalībnieks',
  entries: 'Dalībnieki',
  contestClosed: 'BINGO KONKURSS IR SLĒGTS',
  ifNecessary: 'JA NEPIECIEŠAMS',

  //Signup modal
  reg: 'Jūs esat reģistrējies, lai spēlētu Olybet Bingo',
  rel: 'Jūsu Bingo karte tiks atklāta:',
  come: 'Atgriezieties, lai redzētu savu kartīti, kad notikums sāksies',
  priz: 'Balvas',
  regen: 'BINGO KARTĪTE SPĒLES LAIKĀ AUTOMĀTISKI PIEŠĶIR PUNKTUS',
  //Wait modal
  hangTight: 'SATURIETIES!',
  cardToBeGenerated: 'Gaidām, kad tiks ģenerēta BINGO KARTE.',
  mayTakeUpFewMinutes: 'TAS VAR AIZŅEMT LĪDZ PAT DAŽĀM MINŪTĒM',

  // How to play page
  vert: 'VERTIKĀLI',
  horiz: 'HORIZONTĀLI',
  diag: 'DIAGONĀLI',
  fullCard: 'PILNA KARTĪTE',
  earnEntriesIntoDraw:
    'LAIMĒJIET BALVAS PAR PILNAS RINDAS, DIAGONĀLES VAI PILNAS KARTIŅAS IEGŪŠANU. JŪSU LODZIŅOS TIKS GŪTI PUNKTI, JA NOTIKUMS ATTIECĪGAJĀ LODZIŅĀ KĻŪS PATIESS.',
  autoScoresDuringGame:
    'BINGO KARTĪTE SPĒLES LAIKĀ AUTOMĀTISKI PIEŠĶIR PUNKTUS',
  clickForMoreInfo: 'Lai uzzinātu vairāk, noklikšķiniet šeit, lai skatītu',
  SOG: 'SOG - Metiens pa vārtiem (jebkurš metiens pa vārtiem. Neietver bloķētus metienus vai metienus, kas trāpīja pa vārtu stabu)',
  PPG: 'PPG - Vārti skaitliskajā vairākumā (vārti, ko komanda gūst, spēlējot skaitliskajā vairākumā). Piemēram, komanda, kas spēlē ar skaitlisko pārsvaru 5v4, 5v3 vai 4v3)',
  SHG: 'SHG - Vārti skaitliskajā mazākumā (vārti, kurus guvusi komanda, kuras spēlētājs izcieš sodu)',
  PIM: 'PIM - sodi minūtēs',
  FOPercent: 'FO% - Iemetienu procents',
  PTS: 'PTS - Punkti (vārti vai piespēles)',
  entryPerRow: 'IESNIEGŠANA VIENĀ RINDĀ',
  totalEntries: 'KOPĒJAIS DALĪBNIEKU SKAITS',
  perRow: 'VIENĀ RINDĀ',

  //Home page
  bingoMayTakeUp:
    'Bingo kartēm var būt nepieciešamas līdz 20 minūtēm pēc spēles, lai reģistrētu visus notikumus',
  welcomeTo: 'Laipni lūdzam',
  reviewInRecent: 'Pārskatiet savas iepriekšējās kartes cilnē Nesenās',
  thanksForPlaying:
    'Paldies, ka spēlējat Olybet Bingo! Pārskatiet savas 2022-2023. gada sezonas kartes sadaļā "Nesenās"',
  agreeToRules: 'Reģistrējoties jūs apstiprināt un piekrītat',

  //Recent page
  noRecentContests: 'Nesen notikušu konkursu nav',
  //Game field page
  gamePrizeText:
    'Laimējiet 100 €, ja jums izdosies aizpildīt visus kartiņu, vai 5 €, ja jums izdosies aizpildīt vismaz vienu rindu vai diagonāli. Balvas tiks sadalītas darba laikā.',
  cardUpdateAfterPeriod: 'Kartiņa tiks atjaunināta ik pēc 30 minūtēm',
  anyLine: 'JEBKURA LĪNIJA',
  vertLines: 'VERTIKĀLĀS LĪNIJAS',
  line: 'LĪNIJA',
  horzLines: 'HORIZONTĀLĀS LĪNIJAS',

  //Onboarding page
  letsGetStarted: 'Sāksim',
  makeSureToEnterSelections:
    'Pārliecinieties, ka katru dienu ievadāt savu izvēli, lai sacenstos par ikdienas balvām. Neaizmirstiet ievadīt savu izvēli jautājumam par neizšķirtu atbildi, pirms iesniedzat savu izvēli. Savu izvēli varat rediģēt līdz pat tiešraides spēles sākumam. Pelniet punktus par katru pareizo prognozi.',
  playWithFriends:
    'Spēlējiet ar draugiem. Kam ir spēle? Izaiciniet draugus spēlēt privātā līgā. Dodieties uz līderu tablo sadaļu, nospiediet pogu Izveidot, lai sāktu, un kopīgojiet PIN kodu ar draugiem, lai pievienotos.',
  scoring: 'Vērtēšana',
  challengeFriends: 'Izaicini draugus',
  skip: 'izlaist',
  next: 'nākamais',

  logout: 'Izrakstīties',
  returnToGamezone: 'Atgriezties GameZone',
  rules: 'NOTEIKUMI',
  back: 'Atpakaļ',

  bingo: 'BingOly',
  gamezone: 'Gamezone',
  justAFewMinutes: 'Tikai dažas minūtes, lūdzu, atsvaidziniet lapu',
  fourCorners: 'ČETRI STŪRI',
  rulesNotFound: 'Noteikumi nav atrasti',
  offWebsiteText:
    'PAR GALVENO REZULTĀTU AVOTU TIKS UZSKATĪTA PASĀKUMA OFICIĀLĀ TĪMEKĻA VIETNE. JA TĀDI NEBŪS PIEEJAMI, TIKS IZMANTOTI CITI UZTICAMI AVOTI VAI TIEŠRAIDES VIDEOIERAKSTI.',
};
export default translation;
