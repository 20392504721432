const colors: Record<string, string> = {
  aerospace: '#FF5100',
  white: '#FFFFFF',
  black: '#000000',
  chineseBlack: '#121212',
  eerieBlack: '#1B1B1B',
  raisinBlack: '#262626',
  cyan: '#00B2E3',

  darkBlue: '#0B192F',
  darkGoku: '#19191C',
  greyText: '#999999',
  primaryRed: '#F73535',
  primaryGreen: '#0FDD98',
  primaryBrown: '#231F20',
  primaryBlue: '#1EB9F1',
  activeBlue: '#88B3FF',
  secondaryBlue: '#535374',
  OlybetSecondaryBlue: '#33CFFF',

  gradientOlybet: 'linear-gradient(279deg, #F73535 13.3%, #33CFFF 86.79%)',
};

export default colors;
