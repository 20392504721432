import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { RoutesEnum } from '@/enums/routes.enum';

export const NavWrapper = styled('div')(() => {
  const { pathname } = useLocation();
  return {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '4px',

    ':first-child': {
      borderRadius: '20px 0 0 0',
    },
    ':last-child': {
      borderRadius: '0 20px 0 0',
    },
  };
});

export const StyledLink = styled(Link)<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: selected ? theme.colors.primaryBlue : '#EEF1F5',
    textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '1.4',
    color: selected ? theme.colors.white : '#111111',
    textDecoration: 'none',
    justifyContent: 'center',
    height: '45px',
    padding: '11px',
  }),
);
