import styled from 'styled-components';

export const Top = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  padding: `${theme.space(4.2)} 0`,
}));
export const Content = styled('div')(() => ({
  display: 'grid',
  gap: '4px',
}));
export const Bottom = styled('div')(({ theme }) => ({
  padding: '20px 16px',
}));
