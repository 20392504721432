import React from 'react';

import { RoutesEnum } from '@/enums/routes.enum';

export interface IRoutes {
  path: RoutesEnum;
  element: React.ReactNode;
  children?: IRoutes[];
}

export type TRoutesArgs =
  | { path: RoutesEnum.play }
  | { path: RoutesEnum.recent }
  | { path: RoutesEnum.help }
  | { path: RoutesEnum.splash }
  | { path: RoutesEnum.game }
  | { path: RoutesEnum.lock }
  | { path: RoutesEnum.rules }
  | { path: RoutesEnum.notFound }
  | { path: RoutesEnum.redirectCallback }
  | { path: RoutesEnum.onboarding }
  | { path: RoutesEnum.contestRules; params: { id: string } };

type TArgsWithParams = Extract<TRoutesArgs, { path: RoutesEnum; params: any }>;

export function createPath(args: TRoutesArgs) {
  if (!args.hasOwnProperty('params')) return args.path;

  return Object.entries((args as TArgsWithParams).params).reduce(
    (previousValue: string, [param, value]) =>
      previousValue.replace(`:${param}`, '' + value),
    args.path,
  );
}

export const addIdToRoute = (path: string, providedId: string | undefined) =>
  path.replace('/:id', `/${providedId ?? '/:id'}`);
