import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface StyledButtonProps {
  selected?: boolean;
}

export const StyledButton = styled(Link)<StyledButtonProps>(
  ({ theme, selected }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '20px 16px',
    gap: '20px',
    background: selected ? theme.colors.primaryBlue : '#232856',
    fontSize: '16px',
    fontWeight: '600',
    color: theme.colors.white,
    textDecoration: 'none',
  }),
);
